@import url('https://fonts.googleapis.com/css2?family=Montserrat:');
/* Classes de estilizações gerais do site */

body {
  background-color: #023d81;
  overflow-x: hidden;
  /* Hide scrollbars */
  height: 100% !important;
}

* {
  font-family: 'Montserrat', sans-serif;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  /* font-size: calc(0.6em + 0.3vw + 0.5vh) !important; */
}

a, h4 {
  font-size-adjust: inherit;
}

.camposPix {
  color: white;
  text-align: center;
  margin-top: 4%;
  font-size: calc(0.7em + 0.3vw + 0.5vh) !important;
}
.camposState {
  color: white;
  text-align: center;
  margin-top: 1%;
  font-size: calc(0.7em + 0.3vw + 0.5vh) !important;
}

.PixLogo {
  width: 17%;
  max-width: 250px;
}
.Input1 {
  background-color: #cbdefc;
  margin-top: 4%; 
  border-radius: 5%;
  color: black;
  width: 100%;
  font-size: calc(0.7em + 0.3vw + 0.5vh) !important;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
  padding-left: 0.8rem;
  padding-top: 0.8rem;
}
.Input1::placeholder{
  font-weight: 300;
}

.Button { 
  margin-left: 22%;
  margin-right: 22%;
  margin-top: 4%; 
  display: inline-block;
  padding: 10px 20px;
  font-size: 20px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: black;
  background-color: #cbdefc;
  border: none;
  border-radius: 15px;
  box-shadow: 0 9px #999;
}
.Button:hover {
  background-color: #cbdefc
}
.Button:active {
  background-color: #cbdefc;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}
.cabecalho {
  margin-left: 1, 5%;
  background-color: #023d81;
}

.relatorio {
  background-color: #023d81;
}

.camposFormulario {
  color: white;
  margin-left: "2,7%";
  margin-top: 2%;
  font-size: calc(0.7em + 0.3vw + 0.5vh) !important;
}

.titulosFormulario {
  color: #6a8db5;
  margin-left: "2,7%";
  margin-top: 2%;
  font-size: calc(0.7em + 0.3vw + 0.5vh) !important;
}

.corpo {
  height: 76vh;
  font-size: calc(0.8em + 0.3vw + 0.5vh) !important;
}

.corpocomp{
  margin-top: 2vh;
  margin-left: 4%;
  height: 60vh;
  width: 92%;
  background-color: #cbdefc;
  font-size: calc(0.8em + 0.3vw + 0.5vh) !important;
}

.comprovante {
  color: #404144;
  font-size: calc(0.6em + 0.2vw + 0.5vh) !important;
}

.date {
  color: #6a8db5;
  font-size: calc(0.4em + 0.3vw + 0.5vh) !important;
}

.email{
  border-radius: 10%;
  height: 4vh;
}

.rodapeTitulo {
  font-size: calc(0.6em + 1vw + 0.5vh) !important;
}
.tituloComp{
  color: #3DB0C1;
  margin-top: 30px;
}

.pontilhado {
  margin-left: 1, 6%;
  border-top: 2px dotted #023d81;
  color: #919394;
  background-color: #4c698b;
  height: 1, 5px;
  width: 100%;
}

.cabecalhoLogo {
  width: 100%;
  margin-top: 3%;
  max-width: 250px;
}
.Rodape{
  height: 24vh;
}
.Warning {
  margin-top: 3%;
}
/* aumentar width para 80% quando tiver 2 botoes */
.RodapeButton {
  border-radius: 15px;
  box-shadow: 0 9px #999;
  width: 40%;
  max-width: 250px;
  border:3px inset #000000;
	padding:1px;
}
.RodapeButton:hover {
  background-color: #cbdefc
}
.RodapeButton:active {
  background-color: #cbdefc;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}
.moldura {
	width: 40%; 
	border:1px inset #000000;
	padding:15px;
	background: #0a0a0a;
	}
.RodapeLogoComp {
  width: 90%;
  max-width: 250px;
}

@media (min-width: 576px) {

  .comprovante {
    margin-top: 0 !important;
  }

}
@media (min-width: 576px) {

  .titulosFormulario {
    margin-top: 0;
  }
  .camposFormulario{
    margin-top: 0;
  }

}

@media (max-height: 500px) {
  .titulosFormulario {
    margin-top: 0;
  }
  .cabecalhoLogo,
  .RodapeLogo {
    max-width: 75px;
  }
  .titulosFormulario{
    flex: 0 0 33.333333% !important;
    max-width: 33.333333% !important;
  }
  .camposFormulario{
    margin-top: 0;
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  .corpocomp{
    height: 50vh;
    font-size: calc(0.6em + 0.2vw + 0.4vh) !important;

  }
  .RodapeLogoComp {
    width: 90%;
    max-width: 100px;
  }
  .tituloComp{
    margin-top: 0px;
  }
}
@media (max-height: 730px) {
  .corpocomp{
    height: 50vh;

  }
}
@media (max-height: 600px) {
  .cabecalhoLogo{
  margin-top: 0;
  }
  .corpocomp{
    height: 56vh;
  }
  .Rodape{
    height: 20vh;
  }
}

@media (max-height: 300px) {
  .cabecalhoLogo{
  margin-top: 0;
  max-width: 100px;
  }
  .corpocomp{
    height: 56vh;
  }
  .Rodape{
    height: 20vh;
  }
}
